<template>
    <div class="landing">
        <img :src="logo" alt="Baltic Expo" width="150">
        <div>
            <h2>REGISTRATION STARTS: 07.05.2025. - 10:00</h2>
        </div>
    </div>
</template>

<script setup>
import logo from '../../assets/logo.svg'
</script>

<style lang="sass" scoped>
.landing
    display: flex
    align-items: center
    justify-content: center
    height: 100vh
    width: 100vw
    background: #fff
    flex-direction: column
    gap: 8px
    h2 
        color: #939393
</style>