<template>
    <div class="admin-orders">
        <div class="row">
            <div class="admin-orders__nest">
                <table>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th class="center">Seats</th>
                            <th>Price (EUR)</th>
                            <th>Price + VAT</th>
                            <th>Email</th>
                            <th>Name</th>
                            <th>Phone</th>
                            <th>Company</th>
                            <th>Registration Number</th>
                            <th>Product Description</th>
                            <th class="center">EU VAT Payer</th>
                            <th>Comments</th>
                            <th class="center" style="width: 150px">Invoice</th>
                            <th class="center">Hide</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(order, index) in ordersList" :key="order.id">
                            <td>{{ index + 1 }}</td>
                            <td class="center">
                                <span class="id-list" v-for="(seatId, index) in order.seats" :key="index"> {{ +seatId.id.split('-')[1] }}<br></span>
                            </td>
                            <td>{{ calculatePrice(order.seats) }}</td>
                            <td>{{ Math.round(calculatePrice(order.seats) * 1.21 * 100) / 100 }}</td>
                            <td>{{ order.email }}</td>
                            <td>{{ order.firstName }} {{ order.lastName }}</td>
                            <td>{{ order.phone }}</td>
                            <td>{{ order.company || '-' }}</td>
                            <td>{{ order.registration || '-' }}</td>
                            <td>{{ order.description || '-' }}</td>
                            <td class="center"><input type="checkbox" :checked="order.vatEU" disabled></td>
                            <td>{{ order.comments || '-' }}</td>
                            <td>
                                <app-button class="download-btn" @click="downloadInvoice({...order, vatEU: false})">Download LV</app-button>
                                <app-button class="download-btn" @click="downloadInvoice({...order, vatEU: true})">Download EN</app-button>
                            </td>
                            <td class="center"><input type="checkbox" v-model="order.hide" @change="toggleHide($event, order)"></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>



<script setup>
import { useOrdersStore } from '../../store/orders'
import { calculatePrice } from '../../composables/useHelpers'
import { generatePDF } from '../../composables/useInvoice'
import { onMounted, computed } from 'vue'
import AppButton from '../../components/Button.vue'

const orders = useOrdersStore()
const ordersList = computed(() => {
    return orders.orders.filter(order => orders.showAll ? order : !order.hide)
})

const toggleHide = (e, order) => {
    order.hide = e.target.checked
    orders.updateOrder(order)
}

const downloadInvoice = async (order) => {
    await generatePDF(order).then(base64String => {
        const fileWindow = window.open();
        const dataUrl = 'data:application/pdf;base64,' + base64String;

        fileWindow.document.write(
            '<title>Visualisation</title>' +
            '<body style="overflow: hidden; margin: 0">' +
            '<object width="100%" width="-webkit-fill-available" height="100%" height="-webkit-fill-available" type="application/pdf" data="' + encodeURI(dataUrl) + '"></object>' +
            '</body>'
        );
    });
};

onMounted(async () => {
    await orders.readOrders()
})
</script>

<style lang="sass" scoped>
.admin-orders
    .row
        align-items: flex-start
        gap: 16px

    &__nest
        flex: 1
        border: 1px solid #EFEFEF
        border-radius: 16px
        background: #FFFFFF

    table
        width: 100%

        th, td
            font-size: 14px
            text-align: left
            padding: 4px 8px

            &.center
                text-align: center

        td
            border-top: 1px solid #eeeeee

    .download-btn
        width: 150px

        &:first-child
            margin-bottom: 4px
</style>