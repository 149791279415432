import logo from '../assets/logo.jpg'
import { getStorage, ref, getDownloadURL } from "firebase/storage";

export const generatePDF = async (formData) => {
    const url = 'https://yakpdf.p.rapidapi.com/pdf';
    const options = {
        "async": true,
        "crossDomain": true,
        "url": "https://yakpdf.p.rapidapi.com/pdf",
        "method": "POST",
        "headers": {
            "content-type": "application/json",
            "x-rapidapi-key": "4664f8ea3cmshb5d331e5038f9a3p1d358ajsn144f0adfceb4",
            "x-rapidapi-host": "yakpdf.p.rapidapi.com"
        },
        "processData": false,
        "body": JSON.stringify({
            "source": {
                "html": template[formData.vatEU ? 'eu' : 'lv'](formData)
            },
            "pdf": {
                "format": "A4",
                "scale": 1,
                "printBackground": true
            },
            "wait": {
                "for": "navigation",
                "waitUntil": "load",
                "timeout": 2500
            }
        })
    };

    try {
        const response = await fetch(url, options)
        const blob = await response.blob()

        return blobToBase64(blob)
    } catch (error) {
        console.error(error)
    }
}

export const getRentalAgreement = async () => {
    const storage = getStorage();

    try {
        return await getDownloadURL(ref(storage, 'BalticEXPO-rental-agreement.doc')).then((url) => url)
    } catch (error) {
        console.error(error)
    }
}

const blobToBase64 = blob => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);

    return new Promise(resolve => {
        reader.onloadend = () => {
            // Remove the data URL prefix
            const base64String = reader.result;
            const base64WithoutPrefix = base64String.split(',')[1];
            resolve(base64WithoutPrefix);
        };
    });
};

const dateFormatter = new Intl.DateTimeFormat('lv-LV', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
});

const date = new Date();
const issueDate = dateFormatter.format(date);
const dueDate = dateFormatter.format(new Date().setDate(date.getDate() + 3));

const positionsEU = (seats) => {
    let quantity = 1

    const rows = seats.map(seat => {
        return `
            <tr class="item">
                <td>Participation in the Christmas Market<br> <b>TRADING SPACE (3x2 m) No.: ${seat.id.split('-')[1]}</b></td>
                <td style="text-align: center">${quantity}</td>
                <td style="text-align: center">&euro;${seat.price.toFixed(2)}</td>
                <td style="text-align: right">&euro;${seat.price.toFixed(2)}</td>
            </tr>
        `
    })

    return rows
}

const positionsLV = (seats) => {
    let quantity = 1

    const total = seats.reduce((acc, seat) => {
        return acc + seat.price
    }, 0)

    const rows = seats.map(seat => {
        return `
            <tr class="item">
                <td>Dalība Ziemassvētku gadatirgū<br> <b>VIETA (3x2 m) NR.: ${seat.id.split('-')[1]}</b></td>
                <td style="text-align: center">${quantity}</td>
                <td style="text-align: center">&euro;${seat.price.toFixed(2)}</td>
                <td style="text-align: right">&euro;${seat.price.toFixed(2)}</td>
            </tr>
        `
    })

    return `
        ${rows.join('')}
        <tr class="item">
            <td><b>PVN</b></td>
            <td style="text-align: center">21%</td>
            <td style="text-align: center">-</td>
            <td style="text-align: right">&euro;${(Math.round(total * 0.21 * 100) / 100).toFixed(2)}</td>
        </tr>
    `
}

const total = (seats, vatEU) => {
    return seats.reduce((acc, seat) => {
        return vatEU ? acc + seat.price : acc + (Math.round(seat.price * 1.21 * 100) / 100)
    }, 0)
}

export const invoiceNumber = (seats) => {
    return seats.map(seat => seat.id.split('-')[1]).join('-')
}

const styles = `
    <style>
        .invoice-box {
            max-width: 800px;
            margin: auto;
            padding: 30px;
            font-size: 16px;
            line-height: 24px;
            font-family: 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
            color: #555;
        }
        .invoice-box table {
            width: 100%;
            line-height: inherit;
            text-align: left;
        }
        .invoice-box table td {
            vertical-align: top;
        }
        .invoice-box .heading td,
        .invoice-box .details td,
        .invoice-box .item td,
        .invoice-box .total td {
            padding: 5px;
        }
        .invoice-box table tr td:nth-child(2) {
            text-align: right;
        }
        .invoice-box table tr.top table td {
            padding-bottom: 20px;
        }
        .invoice-box table tr.top table td.title {
            font-size: 45px;
            line-height: 45px;
            color: #333;
        }
        .invoice-box table tr.information table td {
            padding-bottom: 40px;
        }
        .invoice-box table tr.heading td {
            background: #eee;
            border-bottom: 1px solid #ddd;
            font-weight: bold;
        }
        .invoice-box table tr.details td {
            padding-bottom: 20px;
        }
        .invoice-box table tr.item td {
            border-bottom: 1px solid #eee;
        }
        .invoice-box table tr.item.last td {
            border-bottom: none;
        }
        .invoice-box table tr.total {
            border-top: 1px solid #eee;
            font-weight: bold;
        }

        @media only screen and (max-width: 600px) {
            .invoice-box table tr.top table td {
                width: 100%;
                display: block;
                text-align: center;
            }
            .invoice-box table tr.information table td {
                width: 100%;
                display: block;
                text-align: center;
            }
        }
    </style>
`

const template = {
    eu: formData => {
        return `
            <!DOCTYPE html>
                <html>
                    <head>
                        <meta charset="utf-8" />
                        <title>Baltic Expo Invoice</title>
                        ${styles}
                    </head>

                    <body>
                        <div class="invoice-box">
                            <table cellpadding="0" cellspacing="0">
                                <tr class="top">
                                    <td colspan="4">
                                        <table>
                                            <tr>
                                                <td class="title">
                                                    <img src="${logo}" />
                                                </td>

                                                <td>
                                                    <b><u>Invoice No. ${invoiceNumber(formData.seats)}</u></b><br />
                                                    Date: ${issueDate}<br />
                                                    <b>Payment deadline: ${dueDate}</b>
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                                <tr class="information">
                                    <td colspan="4">
                                        <table>
                                            <tr>
                                                <td>
                                                    <b>SUPPLIER: SIA BALTIC EXPO</b><br>
                                                    Registration number: 40203489588<br>
                                                    VAT number: LV40203489588<br>
                                                    Legal address: Mālpils iela 2-4,<br>
                                                    Rīga, LV-1013, Latvija<br>
                                                    Contact information: info@balticexpo.lv<br><br>
                                                    <b>Bank: AS Swedbank<br> Account No.: LV79HABA0551054968883</b>
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                                <tr class="heading">
                                    <td colspan="4">Payment Method</td>
                                </tr>
                                <tr class="details">
                                    <td colspan="4">Bank Transfer</td>
                                </tr>
                                <tr class="heading">
                                    <td style="width: 60%">Position</td>
                                    <td style="text-align: center">Amount</td>
                                    <td style="text-align: center">Price</td>
                                    <td style="text-align: right">Sum</td>
                                </tr>
                                ${positionsEU(formData.seats)}
                                <tr class="item">
                                    <td style="font-size: 12px; line-height: 18px">
                                        <b>VAT Directive 2006/112/EC Art.44, Art.196<br>
                                        Subject to taxes (VAT) in the receiver's country.<br>
                                        Reverse charge</b>
                                    </td>
                                    <td style="text-align: center; font-size: 12px; line-height: 18px">VAT not<br> applicable</td>
                                    <td style="text-align: center">-</td>
                                    <td style="text-align: right">&euro;0.00</td>
                                </tr>
                                <tr class="total">
                                    <td colspan="3"></td>
                                    <td><b>Total: &euro;${total(formData.seats, formData.vatEU).toFixed(2)}</b></td>
                                </tr>
                                <tr class="information">
                                    <td colspan="4">
                                        <table>
                                            <tr>
                                                <td>
                                                    <b>RECIPIENT: ${formData.company}</b><br>
                                                    Registration number: ${formData.registration}<br>
                                                    Contact information: ${formData.email}<br>
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                                <tr class="information">
                                    <td colspan="4">
                                        The invoice has been generated electronically and is valid without a signature.<br>
                                        TRANSFER MUST INCLUDE THE INVOICE NUMBER.
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </body>
                </html>
        `
    },
    lv: formData => {
        return `
            <!DOCTYPE html>
                <html>
                    <head>
                        <meta charset="utf-8" />
                        <title>Baltic Expo Invoice</title>
                        ${styles}
                    </head>

                    <body>
                        <div class="invoice-box">
                            <table cellpadding="0" cellspacing="0">
                                <tr class="top">
                                    <td colspan="4">
                                        <table>
                                            <tr>
                                                <td class="title">
                                                    <img src="${logo}" />
                                                </td>

                                                <td>
                                                    <b><u>Rēķins Nr. ${invoiceNumber(formData.seats)}</u></b><br />
                                                    Datums: ${issueDate}<br />
                                                    <b>Apmaksas termiņš: ${dueDate}</b>
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                                <tr class="information">
                                    <td colspan="4">
                                        <table>
                                            <tr>
                                                <td>
                                                    <b>PIEGĀDĀTĀJS: SIA BALTIC EXPO</b><br>
                                                    Reģistrācijas numurs: 40203489588<br>
                                                    PVN numurs: LV40203489588<br>
                                                    Juridiskā adrese: Mālpils iela 2-4,<br>
                                                    Rīga, LV-1013, Latvija<br>
                                                    Kontaktinformācija: info@balticexpo.lv<br><br>
                                                    <b>Banka AS Swedbank<br> Konta Nr.: LV79HABA0551054968883</b>
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                                <tr class="heading">
                                    <td colspan="4">Apmaksas veids</td>
                                </tr>
                                <tr class="details">
                                    <td colspan="4">Pārskaitījums</td>
                                </tr>
                                <tr class="heading">
                                    <td style="width: 60%">Nosaukums</td>
                                    <td style="text-align: center">SKAITS</td>
                                    <td style="text-align: center">CENA</td>
                                    <td style="text-align: right">SUMMA</td>
                                </tr>
                                ${positionsLV(formData.seats)}
                                <tr class="total">
                                    <td colspan="3"></td>
                                    <td><b>Kopā: &euro;${total(formData.seats, formData.vatEU).toFixed(2)}</b></td>
                                </tr>
                                <tr class="information">
                                    <td colspan="4">
                                        <table>
                                            <tr>
                                                <td>
                                                    <b>SAŅĒMĒJS: ${formData.company}</b><br>
                                                    Registration number: ${formData.registration}<br>
                                                    Contact information: ${formData.email}<br>
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                                <tr class="information">
                                    <td colspan="4">
                                        Rēķins ir izveidots elektroniski un ir derīgs bez paraksta.<br>
                                        PĀRSKAITĪJUMĀ OBLIGĀTI NORĀDĪT RĒĶINA NUMURU.
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </body>
                </html>
        `
    }
}